<template>
	<div :class="themeClass" class="AccountPay-Box padding-lr-13 margin-t-20" v-loading="finishLoading">

		<div class="title font-size18 font-color-333 font-weight700">
			交易通钱包
		</div>
		<div class="unboundCard flex-row-space-between-center" v-if="AccountPayWalletInfo != null && hasOpening == false && rechargeACout.length == 0">
			<img class="imgbox" src="../../assets/u1208.png" alt="">
			<div style="margin-left: 140px;" class="flex-row-space-between-center">
				<div style="font-size:18px; color: #CC0000; font-weight: 700;">电子账户未绑定实体账户风险提示</div>
				<div style="font-size:13px; color: #000;">（为保障您的资金安全及交易顺畅，请及时绑卡）</div>
			</div>
			<div style="font-size:16px; color: #CC0000;cursor:pointer" class="flex-row-space-between-center" @click="showAgreeDia" >
				<i style="font-size: 18px;margin-right:5px;" class="el-icon-info"></i>
				<div>查看详情</div>
			</div>
		</div>
		<div v-if="AccountPayWalletInfo == null && hasOpening == false" class="position-relative flex-column-center-center font-size14">
			<div class="flex-row-center-center width-fill openACcount">
				<svg-icon icon-class="NoRightsAndInterests" />
			</div>
			<div class="text-center position-absolute" style="bottom:-70px">
				<div class="font-color-999 margin-t-5">亲，您还没有开通交易通钱包服务，去开通体验吧！</div>
				<div class="font-color-999 margin-t-5">交易通钱包是购买自营店商品时可用的货币资金付款方式。</div>
				<div class="open text-center background-color-theme pointer" @click="openAccountPay('开通交易通')">开通交易通钱包</div>
			</div>
		</div>
		<div v-else-if="AccountPayWalletInfo != null && hasOpening == true" class="flex-row width-fill">
			<div class="flex-row" style="width: 100%; justify-content: space-between;position: relative;align-items: center;">
				<div class="carsd font-color-fff flex-column-align-center">
					
					<div class="font-size15 font-weight700 margin-t-25">
						{{getUserInfo.customerName}}
				    </div>
					<div class="margin-t-30 flex-row">
						<el-button :disabled="showEsult" :loading="showEsult" v-if="AccountPayWalletInfo && AccountPayWalletInfo.fStatus == 2" class='button-box-CZ flex-row-center-center pointer' @click="getresult">
							结果查询
						</el-button>
					</div>
					<div v-if="AccountPayWalletInfo && AccountPayWalletInfo.fStatus == 2" class="font-size13 font-weight700" style="margin-top: 25px; text-align: center;">开户中，请等待或点击结果查询</div>
					<div v-if="AccountPayWalletInfo && AccountPayWalletInfo.fStatus == 3" class="font-size13 font-weight700" style="margin-top: 70px; text-align: center;">CRM风险核查异常，落地银行人工处理，请等待...</div>
				</div>
				<div class="rightCard font-color-fff"  style="position: relative;">
					<!--左侧logo图-->
					<div style="z-index: 9;margin-left: 31px;">
						<img style="width: 120px;height: 109px;" src="../../assets/u442.png"></img>
					</div>
					<!--右侧提示语-->
					<div class="rightCardSize" style="z-index: 9;color:#1e1e1e">
						<div style=" color:#E91D2C;font-size: 20px;font-weight: 700;letter-spacing: 2px;">温馨提示：</div>
						<div style=" color:#6D6D6D;font-size: 16px;letter-spacing: 2px;font-weight: 500; ">交易通钱包仅用于自营店<br/>
						<div style="margin-top: -4px !important;">商品下单支付使用</div></div>
					</div>
				</div>
			</div>
			
		</div>
		
		<div v-else class="flex-row width-fill">
			<div class="flex-row" style="width: 100%; justify-content: space-between;position: relative;align-items: center;">
				<div style="padding: 15px;" class="carsd font-color-fff">
					<div class="font-size15 font-weight700 margin-t-5"  >
						{{AccountPayWalletInfo.fAccountName}}
						<!-- <div class="fonrc pointer" @click="AccountCancellation">销户</div> -->
				    </div>
					<div class="font-size15 margin-t-5">
						{{AccountPayWalletInfo.fAccountNumber}}
						<i v-if="!AccountPayWalletInfo.fIsShow" slot="suffix" style="padding-left: 5px;font-size:16px;" class="iconfont font-size18 icon-yanjing1" @click="tabto"></i>
					</div>
					<div class="font-size15 margin-t-5">{{AccountPayWalletInfo.fjcBankName + '(' + '联行号: ' + AccountPayWalletInfo.fjcAccountNumber + ')'}}</div>
					<div class="margin-t-5">
						<div class="font-size12" >
							<span style="vertical-align: middle;">当前余额</span>
							<div v-if="passShow"  class="font-size22 font-weight700" style="vertical-align: middle;display: inline-block; margin-left: 10px;" >¥ {{balance | NumFormat}}</div>
							<div v-else class="font-weight700" style="vertical-align: middle;display: inline-block; font-size: 33px; line-height: 21px; margin-top: 8px; margin-left: 10px;" >******</div>
							<i slot="suffix" style="padding-left: 5px;" class="iconfont font-size18" :class="passShow?'icon-eye':'icon-yanjing1'" @click="getbalance"></i>
						</div>
					</div>
					<div class="font-size15 margin-t-5">
						<div  class="flex-row-end-center">
							<el-button style="background: rgba(0,0,0,0); color: #fff;border-radius: 2px 0 0 2px;border-right: 0px" class='button-box-TX flex-row-center-center pointer' @click="showCzPrompt">
								充值
							</el-button>
							<el-button style="margin-left:0;border-radius: 0 2px 2px 0;" class='button-box-CZ flex-row-center-center pointer' @click="toWithdrawal">
								提现
							</el-button>
						</div>
					</div>
				</div>
				<div class="rightCard font-color-fff"  style="position: relative;">
					<!--左侧logo图-->
					<div style="z-index: 9;margin-left: 31px;">
						<img style="width: 120px;height: 109px;" src="../../assets/u442.png"></img>
					</div>
					<!--右侧提示语-->
					<div class="rightCardSize" style="z-index: 9;color:#1e1e1e">
						<div style=" color:#E91D2C;font-size: 20px;font-weight: 700;letter-spacing: 2px;">温馨提示：</div>
						<div style=" color:#6D6D6D;font-size: 16px;letter-spacing: 2px;font-weight: 500; ">交易通钱包仅用于自营店<br/>
						<div style="margin-top: -4px !important;">商品下单支付使用</div></div>
					</div>
				</div>
			</div>
		</div>
		<div class="seleAcuto" v-if="AccountPayWalletInfo != null && hasOpening == false && rechargeACout.length > 0">
			<span>交易通已绑定提现银行账户</span>
			<span @click="tabto" class="spans">查看银行账户 > </span>
		</div>
		<div class="seleAcuto" v-if="AccountPayWalletInfo != null && hasOpening == false && rechargeACout.length == 0">
			<span>交易通未绑定提现银行账户</span>
			<span @click="tabto" class="spans">前去绑定 > </span>
		</div>
		
		<!-- 时间状态筛选 -->
		<div v-if="AccountPayWalletInfo != null && hasOpening == false">
			<el-select v-model="checkedDate" placeholder="时间选择" @change="dateChange" style="margin-top: 15px;" @clear="clearDate"	>
			    <el-option
			      v-for="item in TimeArr"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
		</div>
		
		<!-- 时间状态筛选结束 -->
		<div v-if="AccountPayWalletInfo != null && hasOpening == false">
			<div class="tab text-center margin-t-10 flex-row-space-between">
				<div class="flex-row">
					<div class="tab-item" 
					:class="tabSelect == 0 ? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2': ''"
					 @click="tabClick(0)">
						提现明细
					</div>
					<div class="tab-item"
					:class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2': ''"
					 @click="tabClick(1)">
						收支明细
					</div>
					<div class="tab-item"
					:class="tabSelect == 2 ? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2': ''"
					 @click="tabClick(2)">
						银行流水
					</div>
					<div class="tab-item"
					:class="tabSelect == 3 ? 'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2': ''"
					 @click="tabClick(3)">
						充值记录
					</div>
				</div>
				<div style="color:#0173FE;margin-right: 20px;cursor: pointer;" @click="handleCurrentMonthBilling(1)">
					<i class="iconfont icon-download-1-copy" style="margin-right: 2px;"></i>
					下载月账单
				</div>
				<!-- <div class="mycard background-color-theme font-color-fff" @click="toMyBankCard">我的银行卡</div> -->
			</div>
			<div class="bill" v-if="tabSelect == 0" v-loading="TXloading">
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in cashdetails" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							交易通钱包提现(单号:&nbsp; <span>{{ item.fRefundBillNumber }}</span>)
							<span class="margin-l-10">提现卡号: {{item.fReceiveAccountNumber}}</span>
							<span :class="item.fStatusName === '提现成功' ? 'successBgc' : item.fStatusName === '提现失败' ? 'errorBgc' : 'bg-FF9900' " class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.fStatusName }}</span>
						</div>
						<div class="font-size14">¥{{ item.fRefundMoney | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								{{ item.fShopName}}
								<span class="font-color-999 font-size12">{{item.fCreateTime}}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="pagination flex-row-center-flex-end ">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCount" :total="total" :current-page="page"
					 @current-change="handleCurrentChange"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="cashdetails.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
			<div class="bill" v-if="tabSelect == 1"  v-loading="SZloading">
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in detailData" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							<span>{{ item.FTxt }}</span>
							<span :class="item.FStatusName.includes('成功') || item.FStatusName.includes('已审核') ? 'successBgc' : item.FStatusName.includes('不同意') ? 'errorBgc' : 'bg-FF9900'" class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.FStatusName }}</span>
						</div>
						<div class="font-size14">¥{{ item.FMoney | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								<span class="font-color-999 font-size12">{{item.FTime}}</span>
							</div>
						</div>
			
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="pagesizet" :total="totalt" :current-page="currentPaget" @current-change="handleCurrentChanget"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="detailData.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
			<div class="bill" v-if="tabSelect == 2"  v-loading="LSloading">
				<div class="font-size12 bgc-pink font-color-FF9900 width-fill flex-row"><i
					 class="iconfont flex-row-center-center margin-r-5 icon-tishi5 font-size18"></i>温馨提示：银行流水建议次日查询，因银行结算原因当日交易顺序可能存在变化或延迟。
				</div>
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in statementsData" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							<span>{{ item.fClttxt }}</span>
							<span :class="item.fOrdstaName.includes('处理完成')? 'successBgc' : 'bg-FF9900'" class="audit text-center margin-l-10 bg-FF9900 font-color-fff font-size12">{{ item.fOrdstaName }}</span>
						</div>
						<div class="font-size14">¥{{ item.fTrxamt | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								<span style="padding-right: 5px;" class="font-size14">{{ item.fTrstypName }}</span> <span class="font-color-999 font-size12">{{item.fIsudat + ' ' + item.fIsutim}}</span>
							</div>
						</div>
						<div v-if="item.fIsDownload" style="padding: 0 10px; background-color: #0173FE;color: #fff; cursor: pointer;" @click="dowmloadfile(item)">
							下载回执
						</div>
			
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="statementsCount" :total="statementsTotalt" :current-page="statementsPage" @current-change="handleCurrentStatements"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="statementsData.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
			<div class="bill" v-if="tabSelect == 3"  v-loading="CZloading">
				<!-- <div class="font-size12 bgc-pink font-color-FF9900 width-fill flex-row">
					<iclass="iconfont flex-row-center-center margin-r-5 icon-tishi5 font-size18"></i>
					温馨提示：银行流水建议次日查询，因银行结算原因当日交易顺序可能存在变化或延迟。
				</div> -->
				<div class="bill-item border-b-F2F2F2" v-for="(item, index) in RechargeData" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">
							<span>{{ item.fTrxtxt }}</span>
							<span :class="item.fStatus==1 || item.fStatus==5? 'successBgc' : item.fStatus==2 || item.fStatus==4 ? 'errorBgc' : 'bg-FF9900'" 
							class="audit text-center margin-l-10 font-color-fff font-size12">{{ item.fStatusName }}</span>
						</div>
						<div class="font-size14">¥{{ item.fTrxamt | NumFormat }}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12">
								<!-- <span style="padding-right: 5px;" class="font-size14">{{ item.fTrstypName }}</span> -->
								<span class="font-color-999 font-size12">{{item.fEtytim}}</span>
							</div>
						</div>
						<div v-if="item.fStatus == 2 || item.fStatus == 4" style="padding: 0 10px; background-color: #0173FE;color: #fff; cursor: pointer;" @click="refundBtn(item)">
							解冻退回
						</div>
			
					</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="total,prev, pager, next" :page-size="RechargeSize" :total="RechargeTotal" :current-page="RechargePage" @current-change="handleCurrentRecord"></el-pagination>
				</div>
				<div class="flex-row-center-center" v-if="RechargeData.length == 0">
					<svg-icon icon-class="NoRightsAndInterests" />
				</div>
			</div>
		</div>
		<el-dialog v-if="AccountPayWalletInfo != null && hasOpening == false" title="温馨提示" :visible.sync="dialogCzPrompt" width="25%" :close-on-click-modal="false">
			<p>
				{{'请登录任意同名账户网银，以转账方式汇入您已申请开通的招商银行交易通账户，账户名：' + AccountPayWalletInfo.fAccountName +'，账号' + AccountPayWalletInfo.fAccountNumber + '。转账成功后，可在交易通钱包查询余额。'}}
			</p>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogCzPrompt = false">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog v-dialogDrag v-if="isShowMonthBilling" title="月账单下载" :visible.sync="isShowMonthBilling" width="600px"
		 :close-on-press-escape="false" :close-on-click-modal="false">
			<el-table :data="MonthBillingList"  border :header-cell-style="{ 'text-align': 'center'}"
			 style="width: 100%; margin-top:5px;" height="500px" ref="multipleTable">
				<el-table-column type="index" label="序号" fixed width="50" align="center" />
				<el-table-column prop="fYearPeriod"  label="年月"></el-table-column>
				<el-table-column prop="fBeginDate" label="开始时间"></el-table-column>
				<el-table-column prop="fEndDate" label="结束时间"></el-table-column>
				<el-table-column label="下载">
				    <template slot-scope="scope">
				        <el-button
							v-if="scope.row.fIsDownload"
							size="text"
							@click="dowmloadfile(scope.row)">下载</el-button>
				    </template>
				</el-table-column>
			</el-table>
			<div style="margin-top:10px;">
				<el-pagination background layout="total,prev, pager, next" :page-size="MonthBillingCount" :total="MonthBillingtotal" :current-page="MonthBillingPage" @current-change="handleCurrentMonthBilling"></el-pagination>
			</div>
			<div style="overflow: hidden">
				<el-button style="float: right;" @click="isShowMonthBilling = false" class="table-btn background-color-theme">关 闭</el-button>
			</div>
		</el-dialog>
		<agreementDialog :iContents='iContent' ref="areDialog"></agreementDialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from "@/components/pagination/pagination.vue";
	import agreementDialog from '@/components/agreementDialog';
	export default {
		components: {
			agreementDialog,
			pagination
		},
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error("金额必须为数字值且最多保留两位小数"));
				} else {
					callback();
				}
			};
			return {
				iContent: '',
				MonthBillingCount: 20,
				MonthBillingPage: 1,
				isShowMonthBilling: false, // 下载月账单弹框
				MonthBillingList: [], // 月账单列表
				MonthBillingtotal: 0, // 月账单数量
				TXloading: false, // 提现明细动画
				SZloading: false, // 收支明细动画
				LSloading: false, // 流水明细动画
				CZloading: false, // 充值记录动画
				passShow: false, // 显示余额
				dialogCzPrompt: false, // 充值提示弹框
				showbalance: true,
				rechargeACout: [],
				showEsult: false,
				finishLoading: true,
				hasOpening: false, // 是否有开户汇总的数据
				canReload: true, // 是否可以再次查询
				balance: null, // 余额查询
				pagrec:1,//收入明细当前页
				pagpay:1,//支出明细当前页
				withdraw: [],
				cashdetails: [],
				pagesizet: 6, //收支每页显示10条数据
				currentPaget: 1, //收支当前页
				totalt: 0,
				maxResultCount: 6, //提现每页显示条数
				page: 1, //提现页数
				total: 0,
				tabSelect: 0,
				userinfo: {}, //用户信息
				AccountPayWalletInfo: null, //交易通钱包信息
				
				B2bAccount:null,
				
				TimeArr: [], // 时间搜索框
				checkedDate: 5,
				fBeginDate:new Date()- 3600 * 1000 * 24 * 7,
				fEndDate: new Date(),
				fPlyingStatusList: [ // 充值状态
					{
						value:0,
						label: "未支付",
					},
					{
						value:1,
						label: "支付中",
					},
					{
						value:2,
						label: "支付成功",
					},
					{
						value:3,
						label: "支付失败",
					},
				],
				fPlyingStatus: '',
				detailData: [],
				
				statementsData: [], // 流水明细
				statementsTotalt: 0,// 流水明细总数
				statementsCount: 6, //提现每页显示条数
				statementsPage: 1, //提现页数
				
				RechargePage: 1, // 充值页数
				RechargeSize: 6, // 充值条数
				RechargeTotal: 0, // 充值条数
				RechargeData: [], // 充值记录
			};
		},
		computed: {
			current: {
				get() {
					return this.currentPage;
				},
				set() {},
			},
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},

		// 监听页面滚动条上拉至底部事件

		mounted() {
			//今年的第一天和最后一天
			let year1 = new Date().getFullYear();
			let yearFirstday1 = new Date(year1, 0, 1);
			let yearFirstday1End = new Date(year1, 11, 31);
			//去年的第一天和最后一天
			let year2 = new Date().getFullYear() -1;
			let yearFirstday2 = new Date(year2, 0, 1);
			let yearFirstday2End = new Date(year2, 11, 31);
			//前年的第一天和最后一天
			let year3 = new Date().getFullYear() -2;
			let yearFirstday3 = new Date(year3, 0, 1);
			let yearFirstday3End = new Date(year3, 11, 31);
			//获取前年之前所有的年份时间
			let yearFirstday4End = new Date(year3-1, 11, 31);
			let newarray = [] //时间选择的数组
			for(let i=0;i<6;i++){
				if(i==0){
					newarray.push({
						value:5,
						label: "最近7天",
						fBeginDate:new Date()- 3600 * 1000 * 24 * 7,
						fEndDate:new Date()
					})
				}else if(i==1){
					newarray.push({
						value:0,
						label: "近3个月",
						fBeginDate:new Date()- 3600 * 1000 * 24 * 90,
						fEndDate:new Date()
					})
				}else if(i==2){
					newarray.push({
						value: 1,
						label: "今年",
						fBeginDate:yearFirstday1,
						fEndDate:yearFirstday1End
					})
				}else if(i==3){
					newarray.push({
						value: 2,
						label: (new Date().getFullYear()-1)  + "年",
						fBeginDate:yearFirstday2,
						fEndDate:yearFirstday2End
					})
				}else if(i==4){
					newarray.push({
						value:3,
						label: (new Date().getFullYear()-2)  + "年",
						fBeginDate:yearFirstday3,
						fEndDate:yearFirstday3End
					})
				}else if(i==5){
					newarray.push({
						value:4,
						label: (new Date().getFullYear()-2)  + "年之前",
						fBeginDate:"",
						fEndDate:yearFirstday4End
					})
				}
			}
			this.TimeArr = JSON.parse(JSON.stringify(newarray))
			console.log(this.TimeArr)
			this.getRechargeDetails();
			this.getBaseinfo();
		},
		methods: {
			// 退款按钮
			refundBtn(item) {
				this.$confirm('您确定要退款吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost("/api/mall/payment/jyt-account-binding-record/jyt-ntecobck", {
						"fTrsamt": item.fTrxamt, // 金额
						"fid": item.fBillID,
						"fAccnbr": item.fAccnbr,
						"fRcvacc": item.fRpyacc, // 首付方账号
						"fTrsset": item.fTrxset,  //套号
					}).then(
						(result) => {
							// if (result.obj.status == 1) {
							// 	this.$message.success(result.obj.message)
							// } else {
							// 	this.$message.error(result.obj.message)
							// }
							this.getRechargeRecord()
						},
						(rej) => {
							this.getRechargeRecord()
						}
					);
				}).catch(() => {
				});
			},
			// 查看绑卡协议
			showAgreeDia() {
				this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-list-by-electronic-accounts', {
				}).then(
					res=> {
						this.iContent = res.obj.items[0].fAgreementHistID;
						this.$refs.areDialog.show();
					},
					err => {
						
					}
				).catch(err => {
					
				})
				
			},
			// 充值弹框
			showCzPrompt() {
				if(this.AccountPayWalletInfo.fIsShow) {
					this.dialogCzPrompt = true
				} else{
					this.tabto()
				}
			},
			handleCurrentMonthBilling(val) {
				this.statementsPage = val;
				this.ShowMonthBilling();
			},
			ShowMonthBilling() { //获取月账单数据
				this.isShowMonthBilling = true
				this.ApiRequestPostNOMess('api/mall/ebcustomer/Jyt-deal-account-checkBill/get-list-customer', {
					maxResultCount: this.MonthBillingCount,
					skipCount: (this.MonthBillingPage - 1) * this.MonthBillingCount,
					sorting: ''
				}).then(
				res=> {
					let data = res.obj.items
					data.forEach(item => {
						item.fBeginDate = this.getDate(item.fBeginDate)
						item.fEndDate = this.getDate(item.fEndDate)
					})
					console.log(data)
					this.MonthBillingList= data
					this.MonthBillingtotal = res.obj.totalCount
				},err => {
					this.MonthBillingList = []
				}).catch(err => {
					this.MonthBillingList = []
				})
			},
			// 下载银行流水
			dowmloadfile(item) {
				let url = ''
				let urls = window.API_SERVER;
				url = urls + 'api/henghe/blob/jyt/'+ item.annexlist[0].fPath 
				window.open(url,'_blank',)
			},
			// 显示充值提示弹框
			czInfo() {
				
			},
			//获取个人卡
			getAcsPhysical() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-jyt-physical-account-list').then(
					(result) => {
						this.rechargeACout = [];
						let arrList = [];
						result.obj.forEach(item => {
							item.active = false
						})
						this.rechargeACout = result.obj;
					},
					(rej) => {this.loading = false;}
				);
			},
			// 查询开户中结果
			getresult() {
				this.showEsult = true
				this.ApiRequestPost(
					"api/mall/payment/jyt-account-binding-record/get-jyt-account-check", {
						fAccountID: this.AccountPayWalletInfo.fAccountID * 1,
						// frv: this.AccountPayWalletInfo.frv,
						// rowStatus: this.AccountPayWalletInfo.rowStatus,
					}
				).then(
					(res) => {
						this.showEsult = false
						if (res.obj.status == 1) {
							this.getAccountPayWalletInfo();
						} else {
							this.$message(res.obj.message)
						}
						console.log(res)
					},
					(rej) => {this.showEsult = false}
				);
			},
			// 时间筛选
			dateChange(val) {
				if (val === '') {
					return
				} else {
					for(let item of this.TimeArr){
						if(item.value == val){
							this.fBeginDate = item.fBeginDate
							this.fEndDate =   item.fEndDate
							if (this.tabSelect == 1) {
								this.handleCurrentChanget(1)
							} else if (this.tabSelect == 0) {
								this.handleCurrentChange(1)
							} else if (this.tabSelect == 2) {
								this.handleCurrentStatements(1)
							} else if (this.tabSelect == 3) {
								this.handleCurrentRecord(1)
							}
						}
					}
				}
			},
			clearDate() {
				this.fBeginDate = ''
				this.fEndDate = ''
				this.handleCurrentChange(1)
			},
			tabto() {
				this.$router.push({
					path: '/businessme/JYTallAccount',
					query: {
						title: '交易通银行卡'
					}
				})
			},
			openAccountPay(val) {
				this.$router.push({
					path: '/businessme/bindingJYT',
					query: {
						title: val,
						type: 2,
						fAccountname: '开通交易通'
					}
				}) //跳转企业用户绑卡
			},
			// 销户操作
			AccountCancellation() {
				this.$confirm('您确定要注销账户吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					return
					this.ApiRequestPost("/api/mall/ebcustomer/bank-account/delete-binding-record", {
						fAccountID: e.fAccountID,
						frv: e.frv,
						rowStatus: e.rowStatus
					}).then(
						(result) => {
							if (result.obj.status == 1) {
								this.$message.success(result.obj.message)
							} else {
								this.$message.error(result.obj.message)
							}
							this.getAcsPhysical();
						},
						(rej) => {}
					);
				}).catch(() => {
				});
			},
			// 获取用户信息
			getBaseinfo() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full"
				).then(
					(result) => {
						console.log(result, "获取用户信息");
						if (result.obj != null) {
							this.userinfo = result.obj;
							console.log(this.userinfo);
							// 判断是不是开通了交易通,未开通返回obj等于null
							this.getAccountPayWalletInfo();
							// this.getAcsPhysical();
						}
					},
					(rej) => {}
				);
			},
			//   获取交易通虚拟卡信息
			getAccountPayWalletInfo() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/bank-account/get-jyt-virtual-account"
				).then(
					(result) => {
						if (result.obj == null || result.obj.fCustomerID == null) {
							
							this.isOpening()
							return;
						}
						this.getAcsPhysical()
						this.AccountPayWalletInfo = result.obj;
						this.finishLoading = false
						this.hasOpening = false
						console.log(this.AccountPayWalletInfo, "交易通");
					},
					(rej) => {
						this.finishLoading = false
					}
				);
			},
			// 是否开户中
			isOpening() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/bank-account/get-jyt-account-is-valid"
				).then(
				(res) => {
					this.finishLoading = false
					if (res.obj) {
						if (res.obj.fStatus == 0) {
							this.AccountPayWalletInfo = null
						} else if (res.obj.fStatus == 1) {
							this.getAcsPhysical()
							this.hasOpening = false
							this.AccountPayWalletInfo = res.obj
						} else if (res.obj.fStatus == 2) {
							this.hasOpening = true
							this.AccountPayWalletInfo = res.obj
						} else if (res.obj.fStatus == 3) {
							this.hasOpening = true
							this.$message.warning('黑名单风险核查异常，落地银行人工处理，请等待处理结果或联系客服咨询')
							this.AccountPayWalletInfo = res.obj
						}
					} else {
						this.AccountPayWalletInfo = null
						this.finishLoading = false
					}
					
				},
				(rej) => {
					this.finishLoading = false
					console.log(111111,rej)
				}).catch(err => {
					console.log(22222222222,err)
				})
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getRechargeDetails();
			},
			handleCurrentChanget(val) {
				this.currentPaget = val;
				this.getBalanceDetail();
			},
			handleCurrentStatements(val) {
				this.statementsPage = val;
				this.getStatements();
			},
			handleCurrentRecord(val) {
				this.RechargePage = val;
				this.getRechargeRecord();
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val;
				if (this.tabSelect == 0) { // 提现明细
					this.TXloading = true, // 提现明细动画
					this.SZloading = false, // 收支明细动画
					this.LSloading = false, // 流水明细动画
					this.CZloading = false, // 充值记录动画
					this.page=1
					this.getRechargeDetails();
				} else if(this.tabSelect == 1) { // 收支明细
					this.TXloading = false, // 提现明细动画
					this.SZloading = true, // 收支明细动画
					this.LSloading = false, // 流水明细动画
					this.CZloading = false, // 充值记录动画
					this.currentPaget = 1
					this.getBalanceDetail()
				}  else if(this.tabSelect == 2) { // 流水明细
					this.TXloading = false, // 提现明细动画
					this.SZloading = false, // 收支明细动画
					this.LSloading = true, // 流水明细动画
					this.CZloading = false, // 充值记录动画
					this.statementsPage = 1
					this.getStatements()
				} else if(this.tabSelect == 3) { // 充值记录
					this.TXloading = false, // 提现明细动画
					this.SZloading = false, // 收支明细动画
					this.LSloading = false, // 流水明细动画
					this.CZloading = true, // 充值记录动画
					this.RechargePage = 1
					this.getRechargeRecord()
				}
			},
			// 将毫秒转换为时分秒
			msToTime(milliseconds) {
			  // 初始化变量
			  let hours = Math.floor(milliseconds / 3600000);
			  let minutes = Math.floor((milliseconds % 3600000) / 60000);
			  let seconds = Math.floor((milliseconds % 60000) / 1000);
			 
			  // 补零操作
			  hours = hours < 10 ? '0' + hours : hours;
			  minutes = minutes < 10 ? '0' + minutes : minutes;
			  seconds = seconds < 10 ? '0' + seconds : seconds;
			 
			  // 返回格式化的时间字符串
			  return hours + ':' + minutes + ':' + seconds;
			},
			// 流水明细
			getStatements() {
				this.LSloading = true
				this.ApiRequestPostNOMess(
					"api/mall/ebcustomer/bank-account/get-jyt-account-flowing", {
						fBeginDate: this.fBeginDate==""?null:this.getDate(this.fBeginDate),
						fEndDate: this.fEndDate==""?this.getDate(new Date()):this.getDate(this.fEndDate),
						filter: "",
						maxResultCount: this.statementsCount,
						skipCount: (this.statementsPage - 1) * this.statementsCount,
						sorting: ''
					}
				).then(res => {
					this.LSloading = false
					let arr = res.obj.items
					console.log(arr)
					arr.forEach(item => {
						item.fCreateTime = this.getTime(item.fCreateTime)
						item.fIsudat = this.getDate(item.fIsudat);
						item.fIsutim = this.msToTime(item.fIsutim);
						
					})
					if (arr.length == 0) {
						this.statementsTotalt = 0
					} else {
						this.statementsTotalt = res.obj.totalCount
					}
					this.statementsData = arr
				},
				(rej) => {this.LSloading = false}
				).catch(err => {
					this.LSloading = false
				})
			},
			// 获取收支明细
			getBalanceDetail() {
				this.SZloading = true
				this.ApiRequestPostNOMess(
					"api/mall/ebcustomer/bank-account/get-jyt-account-balance-detail", {
						fBeginDate: this.fBeginDate==""?null:this.getDate(this.fBeginDate),
						fEndDate: this.fEndDate==""?this.getDate(new Date()):this.getDate(this.fEndDate),
						filter: "",
						maxResultCount: this.pagesizet,
						skipCount: (this.currentPaget - 1) * this.pagesizet,
					}
				).then(res => {
					this.SZloading = false
					console.log(res.obj)
					let arr = JSON.parse(res.obj)
					arr.forEach(item => {
						item.FTime = this.getTime(item.FTime)
					})
					if (arr.length == 0) {
						this.totalt = 0
					} else {
						this.totalt = arr[0].FCount
					}
					this.detailData = arr
				},
				(rej) => {this.SZloading = false})
				.catch(err => {
					this.SZloading = false
				})
			},
			// 获取提现明细
			getRechargeDetails() {
				this.TXloading = true
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/refund-bill/get-list-current-customer-jyt-detail", {
						fStatuss: this.fPlyingStatus === '' ? [] : [this.fPlyingStatus*1],
						fBeginDate : this.fBeginDate==""?null:this.getDate(this.fBeginDate),
						fEndDate: this.fEndDate==""?"":this.getDate(this.fEndDate),
						filter: "",
						maxResultCount: this.maxResultCount,
						skipCount: (this.page - 1) * this.maxResultCount,
					}
				).then(
					(result) => {
						this.TXloading = false
						console.log(result, "result111111");
						this.cashdetails = [];
						this.cashdetails = result.obj.items;
						for (let item of result.obj.items) {
							item.fCreateTime = this.getTime(item.fCreateTime);
							
						}
						this.total = result.obj.totalCount
					},
					(rej) => {this.TXloading = false}
				).catch(err => {
					this.TXloading = false
				});
			},
			// 充值记录
			getRechargeRecord() {
				this.CZloading = true
				this.ApiRequestPostNOMess(
					"api/mall/payment/jyt-recharge-bill/get-recharge-list", {
						fStatuss: this.fPlyingStatus === '' ? [] : [this.fPlyingStatus*1],
						fBeginDate : this.fBeginDate==""?null:this.getDate(this.fBeginDate),
						fEndDate: this.fEndDate==""?"":this.getDate(this.fEndDate),
						filter: "",
						maxResultCount: this.RechargeSize,
						skipCount: (this.RechargePage - 1) * this.RechargeSize,
					}
				).then(
					(result) => {
						this.CZloading = false
						console.log(result, "result111111");
						this.RechargeData = [];
						for (let item of result.obj.items) {
							item.fEtytim = this.getTime(item.fEtytim);
							
						}
						this.RechargeData = result.obj.items;
						this.RechargeTotal = result.obj.totalCount
					},
					(rej) => {this.CZloading = false}
				).catch(err => {
					this.CZloading = false
				});
			},
			
			// 提现跳转
			toWithdrawal() {
				// if (this.AccountPayWalletInfo.fEndBalance == 0) {
				// 	this.tipsError("亲,您当前的余额为零哦~")
				// 	return;
				// }
				let obj = {
					type: "withdrawal",
					walletType: "JYT",
				};
				this.$router.push({
					path: "/businessme/jytRechargeWallet",
					query: {
						data: encodeURIComponent(JSON.stringify(obj)),
					},
				});
			},
			updateCountdown(secondsRemaining) {
			  // 显示倒计时
			 
			  // 如果还有剩余时间，则递归调用updateCountdown
			  if (secondsRemaining > 0) {
			    setTimeout(() => {
					console.log(secondsRemaining)
			      this.updateCountdown(secondsRemaining - 1);
			    }, 1000);
			  } else {
			    // 倒计时结束的操作
			  	this.canReload = true
			  }
			},
			// 余额查询
			getbalance() {
				if(this.canReload && !this.passShow) {
					this.passShow = true
					this.canReload = false
					this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-jyt-account-balance')
					  .then(res => {
						this.passShow = true
					    this.canReload = false
					   this.balance = res.obj.avibal;
					   console.log("现金钱包余额刷新", this.balance);
					  }, error => {this.passShow = true});
					
					this.updateCountdown(60)
				} else {
					if(!this.passShow) {
						this.$message('每一分钟可查询余额，请勿频繁操作')
					}
					this.passShow = false
				}
			},
		},
		filters: {
			//格式化银行账户
			BankCardFormat: function(value) {
				// value = value.toString();
				if (value && value.length > 8) {
					return (`${value.substring(0, 4)}` + "<span style='margin:0px 5px;letter-spacing:4.5px;font-weight:700'>" +
						`${"*".repeat(value.length - 8).replace(/(.{4})/g, `
						$1 `)}` + "</span>" + `${value.slice(-4)}`);
				}
				return value;
			},
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-4)}`
			}
		},
	};
</script>
<style lang="scss" scoped="scoped">
	.fonrc{
		font-weight: 400;
		fonr-size: 16px;
		color: #002bff;
		border-bottom: 1px solid #002bff;
		position: absolute;
		right: 15px;
		top: 10px;
	}
	.button-box-TX {
		width: 118px;
		height: 38px;
		border-radius: 2px;
		border: 1px solid #FFFFFF;
		user-select: none;
	}

	.button-box-CZ {
		width: 120px;
		height: 40px;
		border-radius: 2px;
		color: #4180dc;
		background-color: #FFFFFF;
		user-select: none;
	}

	.carsd {
		position: relative;
		flex:1;
		// width: 470px;
		height: 182px;
	    border-radius: 5px 0px 0px 5px;
		box-sizing: border-box;
		overflow: hidden;
		background-image: url(../../assets/JYTbg.png);
		background-size: 100% 100%;
	}

	::v-deep .el-dialog {
		width: 400px;
	}

	.seleAcuto {
		margin-top: 10px;
		border-width: 0px;
		padding: 20px;
		left: 0px;
		top: 0px;
		width: 859px;

		background: inherit;
		background-color: rgba(255, 233, 157, 1);
		border: none;
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.spans {
		cursor: pointer;
		float: right;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.margin-b-8 {
		margin-bottom: 8px;
	}

	.flex-row-Delete {
		width: 57px;
		height: 23px;
		border-radius: 10px;
		text-align: center;
		line-height: 23px;
		// margin-left:95px;
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.margin-b-5 {
		margin-bottom: 5px;
	}

	.margin-l-6 {
		margin-left: 6px;
	}

	.border-b-F2F2F2 {
		border-bottom: 1px solid #f2f2f2;
	}

	.border-t-f2f2f2 {
		border-top: 1px solid #f2f2f2;
	}

	.lh24 {
		height: 24px;
		line-height: 24px;
	}

	.AccountPay-Box {
		width: 100%;
		height: calc(100% - 10px);
		// background-color: #ff3040;
		background-color: #ffffff;
	}

	.title {
		overflow: hidden; //解决盒子塌陷
		margin: 8px 0;
		height: 20px;
		line-height: 20px;
	}

	.card {
		position: relative;
		width: 450px;
		height: 173px;
		background-color: #009eb0;
		// opacity: 0.8;
		box-sizing: border-box;

		// margin-bottom: 20rpx;
		.bg {
			position: absolute;
			width: 100%;
			height: 100%;
			// z-index: -1;
			// opacity: 0.3;
		}

		.shopName {
			height: 15px;
			line-height: 15px;
		}

		.money {
			// margin-top: 5px;
			margin-right: 6px;
			position: absolute;
			top: 25px;
		}

		.card-btn {
			width: 150px;
			margin-top: 6px;
			float: right;
			border: 1px solid #fff;
			position: absolute;
			bottom: 20px;
			left: 280px;
			cursor: pointer;

			.btn1,
			.btn2 {
				display: inline-block;
				width: 75px;
				height: 26px;
				line-height: 25px;
			}
		}
	}

	.tab {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: #f8f9fe;

		.tab-item {
			width: 108px;
			height: 40px;
		}

		.mycard {
			float: right;
			width: 110px;
			height: 30px;
			line-height: 30px;
			margin: auto 13px;
		}
	}

	.bill {
		.bill-item {
			height: 65px;
			padding: 10px 13px;
			box-sizing: border-box;
		}
	}

	.audit {
		display: inline-block;
		// width: 50px;
		padding: 0 5px;
		height: 17px;
		line-height: 17px;
	}

	// .width33{
	// 	width: calc((100%-20px)/3);
	// }

	.bankcard {
		width: 450px;
		height: 173px;
		opacity: 1;
		background: #e1efff;
		overflow: hidden;
		margin-left: 10px;

		.card-top {
			width: 100%;
			height: 125px;
			opacity: 1;
			background: #f8f9fe;
			box-sizing: border-box;
			padding: 15px;
		}

		.bank {
			height: 19px;
			line-height: 19px;

			.bank-icon {
				width: 29px;
				height: 18px;
			}

			.bank-name {
				margin-left: 5px;
			}

			.card-type {
				width: 48px;
				height: 17px;
				line-height: 17px;
				margin-left: 7px;
				border-radius: 2px;
			}

			.bg-33b1c0 {
				background: #33b1c0;
			}
		}

		.cardID {
			height: 24px;
			// margin-top: 12px;
		}

		.userinfo {
			padding-left: 15px;
		}

		.h51 {
			height: 51px;
			line-height: 51px;
			cursor: pointer;
		}

		.h51:hover {
			color: #0173FE;
		}

		.unbundling {
			height: 16px;
			line-height: 16px;
		}

	}

	.border-fff {
		width: 100px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		color: #fff;
		border: 1px solid #fff;
		margin-top: 5px;
	}

	.open {
		width: 235px;
		height: 45px;
		line-height: 45px;
		margin-top: 23px;
		margin-left: 80px;
	}

	.openACcount {
		.svg-icon {
			width: 505px;
			height: 505px;
		}
	}

	.pagination {
		margin-top: 20px;
		float: right;
	}
	.rightCard{
		flex:1;
		height: 175px;
		// background-image: url(../../assets/cfbg.png);
		border-radius: 0px 5px 5px 0px;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	.aaaaa{
		width: 100%;
		height: 100%;
		background-image: url(../../assets/cfbg.png);
		background-size: 100% 100%;
	}
	.rightCardSize{
		width: 203px;
		font-size: 14px;
		line-height: 24px;
		margin-left: 38px;
	}
	.successBgc {
		background-color: #19c500;
	}
	.errorBgc {
		background-color: #ccc;
	}
	/deep/.el-input__icon {
		line-height: 0px;
	}
	.bgc-pink {
		height: 30px;
		background: rgba(255, 245, 223, 1);
		margin-top: 10px;
		line-height: 30px;
		padding: 0 10px;
		box-sizing: border-box;
	}
	.unboundCard {
		height: 64px;
		width: 100%;
		position: relative;
		margin: 33px 0 16px 0;
		border-radius: 5px;
		padding: 0 20px;
		box-sizing: border-box;
		background: linear-gradient(120deg, rgba(221, 235, 255, 1) 0%, rgba(221, 235, 255, 1) 0%, rgba(255, 238, 221, 1) 100%, rgba(255, 238, 221, 1) 100%);
		.imgbox {
			width: 140px;
			height: 93px;
			position: absolute;
			bottom: -9px;
			left: -7px;
		}
	}
</style>
